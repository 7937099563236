/* src/styles/App.css */
body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f5f5f5;
  }
  
  /* Branding Colors */
  .primary {
    color: #22C197;
  }
  .secondary {
    color: #FFC55F;
  }
  
  /* Utility for transparency */
  .primary-transparent {
    background-color: rgba(63, 111, 228, 0.8);
  }
  