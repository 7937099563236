/* src/styles/Sidebar.css */
.sidebar {
  width: 260px;
  background: #F4F6F8;
  padding: 1.3rem;
  border-right: 1px solid #ccc;
  height: 100vh;
  overflow-y: auto;
  position: relative;
}
.create-button-text {
  color: #fff !important;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 0.8rem;

}

.create-session-btn {
  width: 100%;
  height: 50px;
  margin-top: 2rem !important;
  margin-bottom: 3rem !important;
  background-color: #22C197;
  color: #fff !important;
  font-weight: bold;
}

/* Hover background color */
.session-card.hovered {
  background-color: rgba(255, 197, 95, 0.4);
}

/* Selected (currently open) background color */
.session-card.currently-open {
  background-color: rgba(255, 197, 95, 0.8);
}

/* For dark text, you can tweak to a lighter color:
.session-card.currently-open {
  background-color: rgba(0, 150, 0, 0.1);
}
*/
.select-button {
  height: 2rem;
}

/* Reserve a fixed space for the selection toolbar */
.selection-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px; /* fixed height */
  margin-bottom: 0rem;
}

/* When toolbar is inactive, hide its content but keep its space */
.selection-toolbar.hidden {
  visibility: hidden;
}

.session-list {
  margin-top: 0; /* no extra margin since toolbar space is reserved */
}

.session-card {
  margin-bottom: 2px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  position: relative;
}


/* Inner content container that slides right */
.session-card .card-content-inner {
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease-in-out;
  transform: translateX(0);
}

.session-card.hovered .card-content-inner,
.session-card.selection-mode .card-content-inner {
  transform: translateX(20px);
}

/* Checkbox container positioned absolutely inside the card */
.session-checkbox {
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.session-card.hovered .session-checkbox,
.session-card.selection-mode .session-checkbox {
  opacity: 1;
}


.sesh-container {
  display: flex;
  flex-direction: column;
  margin-left: 30px; /* reserve space for checkbox */
}

.session-name {
  font-size: 0.9rem;
  font-family: 'Lora', serif;
  font-weight: 600;
}

.session-date {
  font-size: 0.6rem;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 0;
}
